import React from "react"

const IndexPage = () => (
  <>
    <h1>momenneko.com</h1>
    <p>comming soon...</p>
  </>
)

export default IndexPage
